<template>
  <breadcrumb-page :list="breadcrumb_links"></breadcrumb-page>

  <div id="maincontent_container">
    <submenu-account menu="po"></submenu-account>

    <div class="submenu-content">

       <div class="pt-4">
          <ul class="nav nav-tabs" id="tab">
            <li class="nav-link"><router-link :to="{ name: 'ShipmentTracking'}">Invoice</router-link></li>
            <li class="nav-link active"><router-link :to="{ name: 'WebOrder'}">Web  PO</router-link></li>
        </ul>
      </div>


      <!-- <h5>Web P.O.</h5> -->
      <div class="desc">
        <p>Web P.O.s in the past 30 days:</p>
      </div>
      
      <spinner v-if="loading" />

      <div v-if="error" class="alert alert-danger" role="alert"></div>

      <div v-if="loading == false">
        <table class="table">
          <thead>
            <tr>
              <th>Order Placed Date</th>
              <th>Web P.O.</th>
              <th>Invoice#</th>
              <th>Ship To Name</th>
              <th>Ship To City</th>
              <th>Ship To State</th>
              <th>Quantity</th>
              <!-- <th>Amount</th> -->
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in items" :key="item">
              <td>{{ item.created_at }}</td>
              <td>
                <router-link
                  :to="{ name: 'WebpoDetails', params: { webso: item.web_so } }"
                  >{{ item.web_so }}</router-link
                >
              </td>
              <td>
                <span v-if="item.invno">{{ item.invno }}</span>
                <span v-else>N/A</span>
              </td>
              <td>{{ item.shipping_to_name }}</td>
              <td>{{ item.shipping_city }}</td>
              <td>{{ item.shipping_state }}</td>
              <td>{{ item.total_qty }}</td>
              
              <!-- <td>
                <span v-if="item.total_amount">${{ item.total_amount }}</span>
                <span v-else></span>
              </td> -->
            </tr>
          </tbody>
        </table>
      </div>

      <br />

      <div>
        <p>
          <u>Terms &amp; Conditions</u><br />
          The Terms and Conditions of Sale at
          <router-link class="blue_link" :to="{ name: 'TermsAndConditions' }"
            >www.malabs.com/terms-conditions</router-link
          >
          apply to all transactions. <br /><br />
        </p>
        <p>
          <u>Price and Availability</u><br />
          Please check with your account manager for final pricing and product
          availability (subject to change without prior notice). Unless
          otherwise indicated, all price quotes are based on a cash discount
          offered to all buyers for payment by cash or check as permitted by
          law. Some Brands may not be eligible for sale outside the U.S. Please
          review with your account manager. All logistics charges, fees, costs,
          and taxes are subject to change based on final destination.
          <br /><br />
        </p>

        <p>
          <u>Destination Control Statement</u><br />
          Diversion contrary to US export laws of goods or services purchased
          from Ma Labs is strictly prohibited.
          <br /><br />
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import Spinner from "../../components/Spinner.vue";
import submenuAccount from "../../components/nav/submenuAccount.vue";
import BreadcrumbPage from "../../components/BreadcrumbPage.vue";

import { useStore } from "vuex";
import { ref } from "vue";
import { getAuthAPI } from "../../utils/axios-api";

export default {
  name: "WebOrder",
  components: { Spinner, submenuAccount, BreadcrumbPage },
  setup() {
    const store = useStore();
    const items = ref(null);
    const error = ref(null);
    const loading = ref(true);

    //breadcrumb
    const breadcrumb_links = ref([]);
    breadcrumb_links.value.push({ text: "My Account", link: "" });
    breadcrumb_links.value.push({ text: "Order Status", link: "WebOrder" });
    breadcrumb_links.value.push({ text: "Web P.O.", link: "WebOrder" });

    var is_customer = store.getters.isCustomer;
    if (is_customer) {
      // var user = store.getters.user
      getAuthAPI
        .get("/order/web-po/")
        .then((response) => {
          items.value = response.data;
          loading.value = false;
        })
        .catch((err) => {
          try {
            error.value = err.message;
          }
          catch {
            error.value = "No record is found.";
          }
          loading.value = false;
        });
    }

    return { items, error, loading, breadcrumb_links };
  },
};
</script>

<style scoped>
#tab li.active{
  border-bottom: 8px solid #4d88e8;
  color:#444;
  font-weight: bold;
}
.desc{
  padding: 20px 10px;
}
</style>
